<template>
    <div>
        <base-header :base-title="headTitle"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <router-link
                                class="btn btn-default btn-bold btn-upper btn-font-sm mr-2"
                                :to="{ name: 'skema-rujukan' }"
                            >
                                <i class="flaticon2-back"></i>
                                {{ $t('button.previous') }}
                            </router-link>
                             <template v-if="checkPermission('SkemaRujukanResource.PUT.SkemaRujukan')">
                             <router-link
                                class="btn btn-default btn-bold btn-upper btn-font-sm mr-2"
                                :to="{ name: 'skema-rujukan-edit',  params: { id: skemaRujukan.id }}"
                            >
                                <i class="la la-pencil-square"></i>
                               {{ $t('button.edit') }}
                            </router-link>
                            </template>
                            <button class="btn btn-default btn-bold btn-upper btn-font-md" @click="print()">
                                <i class="fa fa-print"></i>
                                {{ $t('button.print') }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body" v-if="isLoaded" id="printMe">
                    <div class="row">
                        <div class="col-5">
                            <div class="form-group row mb-2">
                                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.name') }}:</label>
                                <label class="col-7 col-form-label">{{skemaRujukan.nama}}</label>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="form-group row mb-2">
                                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.guarantor') }}:</label>
                                <label class="col-7 col-form-label">{{skemaRujukan.jenisPenjamins | nama}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5">
                            <div class="form-group row mb-2">
                                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.periode') }}:</label>
                                <label class="col-7 col-form-label">{{skemaRujukan.tanggalMulai | date}} - {{skemaRujukan.tanggalSelesai | date}}</label>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="form-group row mb-2">
                                <label class="col-3 col-form-label font-weight-bold">{{ $t('common.kategori') }}:</label>
                                <label class="col-7 col-form-label">{{skemaRujukan.kategoris | nama}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="table-responsive">
                            <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>{{ $t('common.tindakan') }}</th>
                                    <th width="15%" v-for="item in kelas" :key="item.id">{{ item.nama }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(parent) in skemaRujukan.parentTindakans" >
                                    <tr :key="parent.id">
                                        <th> {{ parent.nama }}</th>
                                        <th v-for="item in kelas" :key="item.id"></th>
                                    </tr>
                                    <tr v-for="(sub) in parent.subTindakans" :key="sub.id">
                                        <td>{{sub.nama}}</td>
                                        <td v-for="fee in sub.fee" :key="fee.id" class="text-right">
                                            <template v-if="!fee.usePersentase">
                                                {{fee.biaya | seperator }}
                                            </template>
                                            <template v-else>
                                                <div class="col text-right pr-0"> {{ fee.persentase }} % </div>
                                                <div class="col text-right pr-0" v-if="fee.biayaMin"> <strong> Min : </strong> &nbsp; {{ fee.biayaMin | seperator }}</div>
                                                <div class="col text-right pr-0" v-if="fee.biayaMax"> <strong> Max : </strong> &nbsp; {{ fee.biayaMax | seperator }}</div>
                                            </template>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div v-else class="m-auto p-5 d-flex flex-row h-100">
                    <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                    <h5 class="my-auto pl-5">Loading ...</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Validator } from 'vee-validate';
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const SkemaRujukanRepository = RepositoryFactory.get("skemaRujukan");
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import Fee from "./../../model/fee-model";
export default {
    components: {
        BaseHeader,
        BaseTitle,
    },
    provide() {
        return {
        $validator: this.$validator
        };
    },
    data() {
        var vx = this;
        return {
            skemaRujukan: null,
            isLoaded: false,
            headTitle: "Skema Rujukan",
            kelas: [],
        }
    },
    watch: {
    },
    filters: {
        date: function (value) {
            return moment(value).format('DD MMM YYYY');
        },
        nama: function (value) {
            return value.map(x => x.nama).join(', ')
        },
        seperator : function(value){
            if (value !== null) {
                const parts = value.toString().split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                return parts.join(',');
            }
            return value;
        }
    },
    methods: {
        print:function(){
            this.$htmlToPaper('printMe');
        },
        getDataSkema: function(id) {
            SkemaRujukanRepository.getSkema(id)
            .then(res => {
                this.skemaRujukan = res.data;
                this.onInit();
            })
            .catch(error => {
                var msg = (error.message) ? error.message : error;
                this.showToastError('Skema Rujukan Data : ' + msg);
            })

        },
        onInit: function(){
            this.isLoaded = true;
            this.getKelas();
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        },
        showToastError: function(message) {
            toastr.error(message);
        },
        currentDate: function() {
            return moment().format('YYYY-MM-DD')
        },
        getKelas: function() {
            let totalKelas = [];
            this.skemaRujukan.parentTindakans.forEach(parent => {
                parent.subTindakans.forEach(sub => {
                    const kelas = sub.fee.map(x => x.kelasCustom);
                    totalKelas = totalKelas.concat(kelas);
                });
            });
            const result = [];
            const map = new Map();
            totalKelas.forEach(item => {
                if(!map.has(item.id)){
                    map.set(item.id, true);
                    result.push(item);
                }
            });
            result.sort((a,b) => a.kelasOrder - b.kelasOrder);
            this.kelas = result;
            this.constructData();

        },
        constructData: function() {
            this.skemaRujukan.parentTindakans.forEach(parent => {
                parent.subTindakans.forEach(sub => {
                    if (sub.fee.length > 0) {
                        sub.usePersen = false;
                        sub.usePersen = sub.fee[0].usePersentase;
                        // check apakah sama semua fee nya
                        if (sub.usePersen) {
                            sub.isAll = sub.fee.map(x => x.persentase).every((val, i, arr) => val === arr[0]) &&
                                        sub.fee.map(x => x.biayaMin).every((val, i, arr) => val === arr[0]) &&
                                        sub.fee.map(x => x.biayaMax).every((val, i, arr) => val === arr[0])
                                        ? true : false;
                        } else {
                            sub.isAll = sub.fee.map(x => x.biaya).every((val, i, arr) => val === arr[0]);
                        }
                        // chck apakah fee nya ada yg kosong
                        if (sub.fee.length !== this.kelas.length) {
                            sub.isAll = false;
                            this.kelas.forEach(kls => {
                                if (!sub.fee.find(x => x.kelasCustom.id === kls.id)) {
                                    const fee = new Fee();
                                    fee.kelasCustom = kls;
                                    fee.usePersentase = sub.usePersen;
                                    sub.fee.push(fee);
                                }
                            });
                        }
                        sub.fee.sort((a, b) => a.kelasCustom.kelasOrder - b.kelasCustom.kelasOrder);
                        sub.fee.forEach(fee => {
                            fee.msgError = null;
                            fee.isReadonly = sub.isAll;
                        });
                    } else {
                        sub.isAll = false;
                        sub.usePersen = false;
                        this.kelas.forEach(kls => {
                            const fee = new Fee();
                            fee.kelasCustom = kls;
                            sub.fee.push(fee);
                        });
                    }
                });
            });
        }
    },
    created(){
        this.headTitle = "Skema Rujukan";
        this.getDataSkema(this.$route.params.id)

    },
    mounted() {
    }
}
</script>

<style>

</style>
