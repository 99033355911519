export default function Fee() {
    return {
        biaya: null,
        id: null,
        isDeleted: false,
        kelasCustom: null,
        skemaRujukanId: null,
        isReadonly: false,
        msgError: null,
        persentase: null,
        usePersentase: false,
        biayaMin: null,
        biayaMax: null
    };
}
